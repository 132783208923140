import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/MobileView.css';
import Banner1 from "../assets/banner1.png";
import { RotatingLines } from 'react-loader-spinner';

const MobileView = () => {
  const [mobile, setMobile] = useState(''); // State for mobile number
  const [fullName, setFullName] = useState(''); // State for full name
  const [city, setCity] = useState(''); // State for city
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const navigate = useNavigate();
  const [loading ,setLoading] =useState(false);


  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async () => {
    if (!fullName) {
      setErrorMessage('Please enter your name.');
      return;
    }
    // Basic validation
    if (!mobile) {
      setErrorMessage('Please enter your mobile number.');
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(
        'https://bjp-backend-1.onrender.com/api/cards',
        { phoneNumber: mobile ,
          fullName: fullName
         }, // Only send phone number
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Check if response has token (successful)
      if (response.status === 201 && response.data.token) {
        const token = response.data.token;

        // Save the token to localStorage
        localStorage.setItem('token', token);
        setLoading(false);
        // Navigate to the cardinfo page
        navigate('/cardinfo');
      } else {
        setErrorMessage('Verification failed, please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while submitting the form. Please try again.');
      console.error(error);
    }
    finally{
      setLoading(false);

    }
  };

  return (
    <>
   {loading === false ? (
   <div className="mobile-container">
      <div className="header-image">
        <img src={Banner1} alt="Campaign Banner" className="banner-img" />
      </div>

      <div className="input-section">
        {/* <label htmlFor="fullName" className="input-label">Full Name</label> */}
        <input
          type="text"
          id="fullName"
          name="fullName"
          placeholder="Enter your full name"
          className="fullName-input"
          value={fullName}
          onChange={handleFullNameChange}
        />
<br></br>
<br></br>

        {/* <label htmlFor="city" className="input-label">City</label> */}
        <input
          type="text"
          id="city"
          name="city"
          placeholder="Enter your city"
          className="city-input"
          value={city}
          onChange={handleCityChange}
        />
<br></br>
<br></br>
        {/* <label htmlFor="mobile" className="input-label">Mobile Number</label> */}
        <div className="input-wrapper">
          {/* <span className="country-code">+91</span> */}
          <input
            type="number"
            id="mobile"
            name="mobile"
            placeholder="Enter your mobile number"
            className="mobile-input"
            value={mobile}
            onChange={handleMobileChange}
          />
        </div>
        <br></br>
       
        {/* <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id="confirm"
            name="confirm"
            className="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="confirm" className="checkbox-label">
            I certify that the above-provided information is correct and there is no mistake. I know that all further communication will be done based on the provided details.
          </label>
        </div> */}

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button className="verification-btn" onClick={handleSubmit}>
          Get Verification Code
        </button>
      </div>
    </div>) : (
      <div className="container m-0 p-0 pt-3">
            <div className="d-flex justify-content-center mt-5 mb-5" style={{ color: '#FF5733' }}>
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
                strokeColor="#b2b2b2"
                className="loading-style"
              />
            </div>
            <h3 className="bold text-center" style={{ fontWeight: "bold" }}>
              Please wait...
            </h3>
          </div>
    )}

</>
  );
};

export default MobileView;
